<template>
  <div>
    <salutation-select
      v-model="value.salutation"
    ></salutation-select>

    <v-text-field
      :label="t('firstname') + '*'"
      v-model="value.firstname"
      data-cy="firstname"
      filled
      :rules="[
        () => !!value.name || $t('validation.required.default')
      ]"
      hide-details="auto"
    ></v-text-field>

    <v-text-field
      :label="t('lastname') + '*'"
      v-model="value.lastname"
      data-cy="lastname"
      filled
      :rules="[
        () => !!value.name || $t('validation.required.default')
      ]"
      hide-details="auto"
      class="mt-4"
    ></v-text-field>

    <customer-edit-address v-model="value.address"></customer-edit-address>
    <customer-edit-extension-fields v-model="value.extension_fields" address-type="private"></customer-edit-extension-fields>
  </div>
</template>

<script>
import SalutationSelect from '@/components/SalutationSelect'
import CustomerEditAddress from '@/views/CustomerEditAddress'
import CustomerEditExtensionFields from '@/views/CustomerEditExtensionFields'

export default {
  name: "CustomerEditPrivate",
  components: {
    CustomerEditExtensionFields, 
    CustomerEditAddress, 
    SalutationSelect
  },
  props: {
    value: Object
  },
  data() {
    return {}
  },
  methods: {
    t: function (key) {
      return this.$t('customers.edit.private.' + key)
    }
  }
}
</script>
