<template>
  <div>
    <v-row class="mt-1">
      <v-col class="pr-2">
        <v-text-field
          :label="t('phone')"
          v-model="value.phone"
          data-cy="phone"
          filled
          hide-details
        ></v-text-field>
      </v-col>

      <v-col class="pl-2">
        <v-text-field
          :label="t('mobile')"
          v-model="value.mobile_phone"
          data-cy="mobile_phone"
          filled
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-text-field
      :label="t('email')"
      v-model="value.email"
      data-cy="email"
      filled
      class="mt-4"
      hide-details
    ></v-text-field>

    <div v-if="addressType === 'private'">
      <v-row>
        <v-col class="pr-2">
          <app-filter-date-picker
            :filterLabel="t('birth_date')"
            v-model="value.birth_date"
            id="dateStart"
            data-cy="birth_date"
            :clearable="true"
            filled
            class="mt-4"
            hide-details
          ></app-filter-date-picker>
        </v-col>

        <v-col class="pl-2">
          <app-filter-date-picker
            :filterLabel="t('die_date')"
            v-model="value.die_date"
            id="dateStart"
            data-cy="die_date"
            :clearable="true"
            filled
            class="mt-4"
            hide-details
          ></app-filter-date-picker>
        </v-col>
      </v-row>

      <v-textarea
        :label="t('mediate_from')"
        v-model="value.mediate_from"
        data-cy="mediate_from"
        class="mt-1"
        hide-details
      ></v-textarea>

      <v-textarea
        :label="t('family')"
        v-model="value.family"
        data-cy="family"
        class="mt-1"
        hide-details
      ></v-textarea>
    </div>

    <v-textarea
      :label="t('notes')"
      v-model="value.notes"
      data-cy="notes"
      filled
      class="mt-4"
      hide-details
    ></v-textarea>

    <div v-if="addressType === 'private'" class="mt-3" hide-details>
      <span>
        <b>{{ t('cooperation') }}</b>
      </span>

      <v-row class="mt-0">
        <v-col>
          <app-filter-date-picker
            :filterLabel="t('cooperation_start_date')"
            v-model="value.cooperation_start_date"
            data-cy="cooperation_start_date"
            id="dateStart"
            :clearable="true"
          ></app-filter-date-picker>
        </v-col>

        <v-col>
          <app-filter-date-picker
            :filterLabel="t('cooperation_end_date')"
            v-model="value.cooperation_end_date"
            data-cy="cooperation_end_date"
            id="dateStart"
            :clearable="true"
          ></app-filter-date-picker>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import SalutationSelect from '@/components/SalutationSelect'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'

export default {
  name: "CustomerEditExtensionFields",
  components: { AppFilterDatePicker, SalutationSelect },
  props: {
    value: Object,
    addressType: String
  },
  data() {
    return {}
  },
  methods: {
    t: function (key) {
      return this.$t('customers.edit.extension-fields.' + key)
    }
  }
}
</script>
