<template>
  <div>
    <BasicEditContainer
      :title="t('edit.title')"
      ref="editor"
      object-type="Customer"
      :object-name="item.name"
      :data-loaded="dataLoaded"
      @cancel="cancel"
      :save-action="saveItem"
      @saved="onSaved"
      :has-changes="hasChanges"
      :delete-action="deleteItem"
      @deleted="close"
      :allow-delete="true"
    >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <customer-edit-company v-if="isCompany" v-model="item.company"></customer-edit-company>
            <customer-edit-private v-if="isPrivate" v-model="item.private"></customer-edit-private>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('picture.title') }}</v-card-title>
          <v-card-text>
            <PictureUpload v-model="item.profile_picture" aspectMode="quadratic"></PictureUpload>
          </v-card-text>
        </v-card>

        <v-card v-if="isCompany && item.company.contact" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('contact-person.group-title') }}</v-card-title>
          <v-card-text>
            <salutation-select
              v-model="item.company.contact.salutation"
              data-cy="salutation"
              hide-details
            ></salutation-select>

            <v-text-field
              :label="t('contact-person.firstname')"
              v-model="item.company.contact.firstname"
              data-cy="firstname"
              filled
              hide-details
              class="mt-4"
            ></v-text-field>

            <v-text-field
              :label="t('contact-person.lastname')"
              v-model="item.company.contact.lastname"
              data-cy="lastname"
              filled
              hide-details
              class="mt-4"
            ></v-text-field>

            <v-text-field
              :label="t('contact-person.email')"
              v-model="item.company.contact.email"
              data-cy="contact_email"
              filled
              hide-details
              class="mt-4"
            ></v-text-field>

            <v-text-field
              :label="t('contact-person.phone')"
              v-model="item.company.contact.phone"
              data-cy="mobile"
              filled
              hide-details
              class="mt-4"
            ></v-text-field>
          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>
        <v-card v-if="item" elevation="0">
          <v-card-title>{{ t('details.qrcode') }}</v-card-title>
          <v-card-text class="text-center">
            <qrcode :value="item.id" :options="{ width:200 }"></qrcode>
          </v-card-text>
        </v-card>

        <v-card v-if="item" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('details.timemanagement') }}</v-card-title>
          <v-card-text>
            <p>{{ t('details.timemanagement-description') }}</p>

            <v-switch
              class="mt-2"
              v-model="item.is_time_management"
              inset
              :true-value="1"
              :false-value="0"
              :label="item.is_time_management ? t('details.yes') : t('details.no')"
            ></v-switch>
          </v-card-text>
        </v-card>

        <CustomerMapSelect
          class="sp-mar-top-3"
          ref="map"
          :initLocation="currentCustomerLocation"
          @save="saveCustomerLocation"
          @setCustomerLocation="setCustomerLocation"
        ></CustomerMapSelect>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('users.title') }}</v-card-title>
          <v-card-text>
            <div>
              <v-text-field
                :label="t('users.search')"
                v-model="searchUsers"
                hide-details
                filled
                append-icon="mdi-magnify"
              ></v-text-field>
            </div>

            <div class="search-items-wrapper">
              <div class="search-items" v-for="item in users" :key="item.id"
                @click="selectItem('users', item)" v-if="searchUsers !== ''">
                {{ item.display_name }}
              </div>
            </div>

            <v-list v-if="item.assigned.users.length > 0" lines="one" class="assigned-items-list">
              <v-list-item
                v-for="(item, index) in item.assigned.users"
                class="employee-element passive"
                :key="index"
              >
                <span class="ellipsis"><v-icon>mdi-account-lock-open-outline</v-icon>{{ item.display_name }}</span>
                <v-icon class="employee-action" color="primary" @click.native="removeItem('users', index)">mdi-minus-circle</v-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <v-btn
          color="primary"
          elevation="0"
          class="icon-left action-button sp-mar-top-3"
          outlined
          x-large
          data-cy="projectButton"
          :to="{ path: '/customers/'+itemId+'/projects' }"
        >
          <v-icon>mdi-forward</v-icon>
          {{ t('manage-projects') }}
        </v-btn>

        <v-btn
          color="primary"
          elevation="0"
          class="icon-left action-button mt-3"
          outlined
          x-large
          data-cy="customerButton"
          :to="{ path: '/users?customer_id=' + itemId }"
        >
          <v-icon>mdi-forward</v-icon>
          {{ t('manage-contacts') }}
        </v-btn>

      </template>
    </BasicEditContainer>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import axios from 'axios'
import _ from 'lodash'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import qrcode from '@chenfengyuan/vue-qrcode'
import CustomerMapSelect from '@/components/CustomerMapSelect'
import SalutationSelect from '@/components/SalutationSelect'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import PictureUpload from '@/components/inputs/PictureUpload'
import CustomerEditPrivate from '@/views/CustomerEditPrivate'
import CustomerEditCompany from '@/views/CustomerEditCompany'

export default {
  name: "CustomerEdit",
  components: {
    CustomerEditCompany, CustomerEditPrivate,
    BasicEditContainer,
    CancelButtonDialog,
    CustomerMapSelect,
    qrcode,
    SalutationSelect,
    AppFilterDatePicker,
    PictureUpload
  },
  data() {
    return {
      itemId: null,
      item: {
        id: null,
        profile_picture: '',
        assigned: {
          users: []
        },
        address_type: 'company',
        company: {
          name: '',
          address: {
            address_display: null,
            address_line_1: '',
            address_line_2: null,
            zip_code: null,
            place: null,
            country: 'CH'
          },
          contact: {},
          extension_fields: {}
        },
        private: {
          salutation: '',
          firstname: '',
          lastname: '',
          address: {
            address_display: null,
            address_line_1: '',
            address_line_2: null,
            zip_code: null,
            place: null,
            country: 'CH'
          },
        },
        location: {
          latitude: null,
          longitude: null,
          radius: null,
        },
        project_id: '',
        contact_user: {
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
        }
      },
      searchUsers: '',
      users: '',
      itemOriginal: null,
      dataLoaded: false,
      currentCustomerLocation: null,
    }
  },
  async created() {
    this.itemId = this.$route.params.id
    this.loadData()
  },
  watch: {
    searchUsers: function (val) {
      this.getSearchResultUsers(encodeURIComponent(val))
    }
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal)
    },
    readonly() {
      return this.item.ext_id
    },
    isCompany() {
      return this.item.address_type === 'company'
    },
    isPrivate() {
      return this.item.address_type === 'private'
    },
  },
  methods: {
    t: function (key) {
      return this.$t('customers.detail.' + key)
    },
    loadData() {
      HTTP.get('customers/' + this.itemId).then(function (response) {
        this.item = response.data
        this.itemOriginal = JSON.parse(JSON.stringify(this.item))

        // set init Location for map select if data exists
        if (this.itemOriginal.location) {
          this.currentCustomerLocation = {
            location: {
              lat: this.itemOriginal.location.latitude,
              lng: this.itemOriginal.location.longitude,
            },
            radius: this.itemOriginal.location.radius
          }
        }
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {    
        console.log(error)
      }.bind(this))
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({ name: 'Customers' })
    },
    deleteItem() {
      return HTTP.delete('customers/' + this.itemId)
    },
    saveItem() {
      const data = {...this.item}
      return HTTP.patch('customers/' + this.item.id, data)
    },
    setCustomerLocation(data) {
      this.item.location.latitude = data.location.lat
      this.item.location.longitude = data.location.lng
      this.item.location.radius = data.radius
    },
    async saveCustomerLocation(data) {
      //
    },
    onSaved() {
      this.close()
    },
    getSearchResultUsers(val) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('users?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
          this.users = response.data
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    selectItem(type, item) {
      this.item.assigned[type].push(item)
      this.searchUsers = ''
      this.checkDuplicates(type)
    },
    checkDuplicates(type) {
        this.item.assigned[type] = _.uniqBy(this.item.assigned[type], 'id')
    },
    removeItem(type, index) {
        this.item.assigned[type].splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.action-button {
  width: 100%;
}

.search-items-wrapper {

  .search-items:first-child {
    margin-top: 10px;
  }

  .search-items:last-child {
    margin-bottom: 0;
  }
}

.assigned-items-list {
  margin-top: 16px;
  
  & > div {
    padding: 10px;
    min-height: auto;
  }
}
</style>
