var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('components.customer-map-select.label'))+" ")]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('GmapAutocomplete',{ref:"gmapAutocomplete",staticClass:"gmap-search",attrs:{"placeholder":_vm.$t('components.customer-map-select.location-input'),"options":{fields: ['geometry', 'formatted_address', 'address_components']},"id":"gmap-search-input"},on:{"place_changed":_vm.onLocationSelected},scopedSlots:_vm._u([{key:"input",fn:function(slotProps){return [_c('v-text-field',{ref:"input",attrs:{"outlined":"","placeholder":"Location Of Event"},on:{"listeners":slotProps.listeners,"attrs":slotProps.attrs}})]}}])}),_c('v-btn',{staticClass:"ml-3",attrs:{"elevation":"0","color":"primary","outlined":"","height":"46px"},on:{"click":function($event){return _vm.clearLocationSearchField()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('components.customer-map-select.radius'),"rules":[
              function (val) { return Number.isInteger(val) && val > 0 || _vm.$t('components.customer-map-select.validation.radius'); }
            ],"data-cy":"radius","hide-details":""},on:{"keyup":function($event){return _vm.$emit('setCustomerLocation', _vm.data)}},model:{value:(_vm.data.radius),callback:function ($$v) {_vm.$set(_vm.data, "radius", _vm._n($$v))},expression:"data.radius"}})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"pr-2"},[_c('v-text-field',{attrs:{"data-cy":"latitude","filled":"","label":_vm.$t('components.customer-map-select.latitude'),"hide-details":""},model:{value:(_vm.data.location.lat),callback:function ($$v) {_vm.$set(_vm.data.location, "lat", _vm._n($$v))},expression:"data.location.lat"}})],1),_c('v-col',{staticClass:"pl-2"},[_c('v-text-field',{attrs:{"data-cy":"longitude","filled":"","label":_vm.$t('components.customer-map-select.longitude'),"hide-details":""},model:{value:(_vm.data.location.lng),callback:function ($$v) {_vm.$set(_vm.data.location, "lng", _vm._n($$v))},expression:"data.location.lng"}})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"350px"},attrs:{"center":{lat: 47.3778579, lng: 8.5403226},"zoom":17,"map-type-id":"terrain","options":{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: false
            }}},[(_vm.selectedPlace.lat != null)?_c('GmapMarker',{attrs:{"icon":_vm.markerOptions,"draggable":true,"position":{
                  lat: _vm.selectedPlace.lat,
                  lng: _vm.selectedPlace.lng
                }},on:{"dragend":_vm.setPosition}}):_vm._e(),(_vm.data.location.lat != null)?_c('GmapCircle',{attrs:{"center":{lat: _vm.data.location.lat, lng: _vm.data.location.lng},"radius":_vm.data.radius,"visible":true,"options":{fillColor:'#009d85',fillOpacity:0.4,strokeColor:'#009d85',strokeWeight:2}}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }