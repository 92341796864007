<template>
  <div>
    <v-text-field
        :label="t('address_line_1')"
        v-model="value.address_line_1"
        data-cy="address.address_line_1"
        filled
        hide-details
        class="mt-4"
    ></v-text-field>

    <v-text-field
        :label="t('address_line_2')"
        v-model="value.address_line_2"
        data-cy="address.address_line_2"
        filled
        hide-details
        class="mt-4"
    ></v-text-field>

    <v-row>
      <v-col class="pr-2">
        <v-text-field
            :label="t('zip_code')"
            v-model="value.zip_code"
            data-cy="address.zip_code"
            filled 
            hide-details
            class="mt-4"
        ></v-text-field>
      </v-col>

      <v-col class="pl-2">
        <v-text-field
          :label="t('city')"
          v-model="value.place"
          data-cy="address.place"
          filled
          hide-details
          class="mt-4"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SalutationSelect from '@/components/SalutationSelect'

export default {
  name: "CustomerEditAddress",
  components: { SalutationSelect },
  props: {
    value: Object
  },
  data() {
    return {}
  },
  methods: {
    t: function (key) {
      return this.$t('customers.edit.address.' + key)
    }
  }
}
</script>
